<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col col-12">
        <h1>Add Category</h1>
        <ul
          class="nav pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block"
        >
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/app" class="active" target="_self">Acceuil</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/app/category" class="active" target="_self">Category</a>
            </li>
            <li class="breadcrumb-item active">
              <span aria-current="location">Add Category</span>
            </li>
          </ol>
        </ul>
        <div class="top-right-button-container">
          <button
            @click="goToAddProduct"
            type="button"
            class="btn top-right-button btn-primary btn-lg"
          >
            Return To Category List
          </button>
        </div>
        <div class="separator mb-5"></div>
      </div>
      <div class="col col-12">
        <div class="card mb-4">
          <div class="card-body">
            <h4 class="card-title">Add New Category</h4>
            <form @submit.prevent="createCategory">
              <fieldset class="form-group">
                <label for="name" class="col-form-label">Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  v-model="category.name"
                  required
                />
              </fieldset>
              <fieldset class="form-group">
                <label for="description" class="col-form-label">
                  Description</label
                >
                <textarea
                  class="form-control"
                  id="description"
                  v-model="category.description"
                  rows="4"
                  required
                ></textarea>
              </fieldset>
              <fieldset class="form-group">
                <label for="image" class="col-form-label"> Image</label>
                <input
                  type="file"
                  id="image"
                  ref="imageInput"
                  accept="image/*"
                  @change="handleImageChange"
                  class="form-control-file"
                  required
                />
              </fieldset>
              <div
                class="col-mb-4 d-flex justify-content-center align-items-center"
              >
                <button type="submit" class="btn-primary-1 btn-lg">
                  Add Category
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import strapi from "@/services/strapi";

export default {
  name: "CreateCategory",
  data() {
    return {
      category: {
        name: "",
        description: "",
        image: null,
      },
    };
  },
  methods: {
    handleImageChange(event) {
      const file = event.target.files[0];
      this.category.image = file;
    },
    async createCategory() {
      try {
        const formData = new FormData();
        formData.append("data", JSON.stringify(this.category));
        formData.append("files.image", this.category.image);

        const response = await strapi.post("/categories", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        console.log("Category created:", response.data);

        // Reset form fields after successful creation
        this.category = {
          name: "",
          description: "",
          image: null,
        };

        // Optionally, navigate to the Category List page or show a success message
        this.$router.push({ name: "CategoryPage" });
      } catch (error) {
        console.error("Error creating category:", error);
        // Handle error and show feedback to the user
      }
    },
    goToAddProduct() {
      // Use router.push to navigate programmatically
      this.$router.push({ name: "CategoryPage" }); // Replace 'CreateProduct' with your actual route name
    },
  },
};
</script>

<style scoped>
/* Add scoped styles here if needed */
</style>
