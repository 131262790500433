import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/css/nav.css"; // Importing the CSS globally
import "./assets/css/index.css"; // Importing the CSS globally
import "./assets/css/app.css";
import "./assets/css/appli.css";
import "./assets/css/chunk-1a6a4dfc.css";
import "./assets/css/chunk-1cc87535.css";
import "./assets/css/chunk-1cd1f97e.css";
import "./assets/css/chunk-1f910687.css";
import "./assets/css/chunk-6ab991c0.css";
import "./assets/css/chunk-6b872f06.css";
import "./assets/css/chunk-6c46d2d3.css";
import "./assets/css/chunk-6d49bd8c.css";
import "./assets/css/chunk-6e3ae64a.css";
import "./assets/css/chunk-6f753ec7.css";
import "./assets/css/chunk-6fbdfde8.css";
import "./assets/css/chunk-16c7613f.css";
import "./assets/css/chunk-17c612d8.css";
import "./assets/css/chunk-20dd8a1b.css";
import "./assets/css/chunk-154d7f15.css";
import "./assets/css/chunk-67043ddc.css";
import "./assets/css/chunk-72454a97.css";
import "./assets/css/chunk-13753184.css";
import "./assets/css/chunk-19901099.css";
import "./assets/css/chunk-vendors.css";
import "./assets/css/compon.css";
import "./assets/css/components.css";
import "./assets/css/dashboards.css";
import "./assets/css/datatables.css";
import "./assets/css/forms.css";
import "./assets/css/product.css";

import "./assets/fonts/simple-line-icons/css/simple-line-icons.css";
import "./assets/fonts/iconsmind-s/css/iconsminds-codes.css";
import "./assets/fonts/iconsmind-s/css/iconsminds-embedded.css";
import "./assets/fonts/iconsmind-s/css/iconsminds-ie7.css";
import "./assets/fonts/iconsmind-s/css/iconsminds.css";

createApp(App).use(router).mount("#app");
