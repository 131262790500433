import { createRouter, createWebHistory } from "vue-router";
import ProductList from "../components/Produit/ProductList.vue";
import CreateProduct from "../components/Produit/CreateProduct.vue";
import NavbarSlider from "../components/Layout/NavbarSlider.vue";

import FooterBar from "../components/Layout/FooterBar.vue";
import CategoryPage from "../components/Categorie/CategoryPage.vue";
import CreateCategory from "../components/Categorie/CreateCategory.vue";
import UpdateCategory from "../components/Categorie/UpdateCategory.vue";
import CategoryDetail from "../components/Categorie/CategoryDetail.vue";
import UpdateProduct from "../components/Produit/UpdateProduct.vue";
import ProductDetail from "../components/Produit/ProductDetail.vue";
import LoginPage from "../components/LoginPage.vue";
import BrandPage from "../components/Brand/BrandPage.vue";
import CreateBrand from "../components/Brand/CreateBrand.vue";
import UpdateBrand from "../components/Brand/UpdateBrand.vue";
import BrandDetail from "../components/Brand/BrandDetail.vue";

import ClientList from "../components/Client/ClientList.vue";
import UpdateClient from "../components/Client/UpdateClient.vue";
import UserList from "../components/User/UserList.vue";
import UserUpdate from "../components/User/UserUpdate.vue";
import UserCreate from "../components/User/UserCreate.vue";
const routes = [
  {
    path: "/",
    name: "Home",
    //meta: { requiresAuth: true }, // Redirect to login page by default
  },
  {
    path: "/Product",
    name: "ProductList",
    component: ProductList,
    //meta: { requiresAuth: true },
  },
  {
    path: "/createProduct",
    name: "CreateProduct",
    component: CreateProduct,
    //meta: { requiresAuth: true },
  },
  {
    path: "/NavbarSlider",
    name: "NavbarSlider",
    component: NavbarSlider,
    //meta: { requiresAuth: true },
  },

  {
    path: "/FooterBar",
    name: "FooterBar",
    component: FooterBar,
    //meta: { requiresAuth: true },
  },
  {
    path: "/Category",
    name: "CategoryPage",
    component: CategoryPage,
    //meta: { requiresAuth: true },
  },
  {
    path: "/CreateCategory",
    name: "CreateCategory",
    component: CreateCategory,
    //meta: { requiresAuth: true },
  },
  {
    path: "/category/update/:id",
    name: "UpdateCategory",
    component: UpdateCategory,
    //meta: { requiresAuth: true },
  },
  {
    path: "/category/detail/:id",
    name: "CategoryDetail",
    component: CategoryDetail,
    //meta: { requiresAuth: true },
  },
  {
    path: "/product/update/:id",
    name: "UpdateProduct",
    component: UpdateProduct,
    //meta: { requiresAuth: true },
  },
  {
    path: "/product/detail/:id",
    name: "ProductDetail",
    component: ProductDetail,
    //meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/Brands",
    name: "BrandPage",
    component: BrandPage,
    //meta: { requiresAuth: true },
  },
  {
    path: "/CreateBrand",
    name: "CreateBrand",
    component: CreateBrand,
    //meta: { requiresAuth: true },
  },
  {
    path: "/brands/update/:id",
    name: "UpdateBrand",
    component: UpdateBrand,
    //meta: { requiresAuth: true },
  },
  {
    path: "/brand/detail/:id",
    name: "BrandDetail",
    component: BrandDetail,
    //meta: { requiresAuth: true },
  },
  {
    path: "/Client",
    name: "ClientList",
    component: ClientList,
    //meta: { requiresAuth: true },
  },
  {
    path: "/Client/update/:id",
    name: "UpdateClient",
    component: UpdateClient,
    //meta: { requiresAuth: true },
  },
  {
    path: "/User",
    name: "UserList",
    component: UserList,
    //meta: { requiresAuth: true },
  },
  {
    path: "/User/update/:id",
    name: "UserUpdate",
    component: UserUpdate,
    //meta: { requiresAuth: true },
  },
  {
    path: "/User/Create",
    name: "UserCreate",
    component: UserCreate,
    //meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Global navigation guard to check for authentication before navigating to protected routes

export default router;
