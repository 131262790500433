<template>
  <div class="container-fluid">
    <div>
      <div class="col col-12">
        <h1>Category List</h1>

        <ul
          class="nav pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block"
        >
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/" class="active" target="_self">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/category" class="active" target="_self">Category</a>
            </li>
            <li class="breadcrumb-item active">
              <span aria-current="location">Category List</span>
            </li>
          </ol>
        </ul>
        <div class="top-right-button-container">
          <button
            @click="goToAddProduct"
            type="button"
            class="btn top-right-button btn-primary btn-lg"
          >
            Add New Category
          </button>
        </div>

        <div class="separator mb-5"></div>
      </div>
      <div class="row mb-5">
        <div class="col col-12">
          <div class="card mb-4">
            <div class="card-body">
              <div class="d-flex justify-content-between mb-2">
                <div class="float-md-right pt-1">
                  <div
                    id="ddown2"
                    class="dropdown b-dropdown d-inline-block btn-group"
                  >
                    <button
                      id="ddown2__BV_toggle_"
                      aria-haspopup="menu"
                      aria-expanded="false"
                      type="button"
                      class="btn dropdown-toggle btn-outline-dark btn-xs"
                    >
                      {{ routesPerPage }}
                    </button>
                    <ul
                      role="menu"
                      tabindex="-1"
                      aria-labelledby="ddown2__BV_toggle_"
                      class="dropdown-menu dropdown-menu-right"
                    >
                      <li role="presentation">
                        <a
                          role="menuitem"
                          href="#"
                          class="dropdown-item"
                          @click.prevent="setRoutesPerPage(10)"
                          >10</a
                        >
                        <a
                          role="menuitem"
                          href="#"
                          class="dropdown-item"
                          @click.prevent="setRoutesPerPage(20)"
                          >20</a
                        >
                        <a
                          role="menuitem"
                          href="#"
                          class="dropdown-item"
                          @click.prevent="setRoutesPerPage(50)"
                          >50</a
                        >
                        <a
                          role="menuitem"
                          href="#"
                          class="dropdown-item"
                          @click.prevent="setRoutesPerPage(100)"
                          >100</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="search-sm">
                  <input
                    type="text"
                    v-model="searchQuery"
                    @input="filterCategories"
                    placeholder="Search..."
                    class="form-control"
                  />
                </div>
              </div>
              <table
                sort-desc.sync="false"
                role="grid"
                aria-busy="false"
                aria-colcount="4"
                aria-multiselectable="true"
                class="table b-table vuetable b-table-selectable b-table-select-multi"
                id="__BVID__301"
              >
                <thead role="rowgroup">
                  <tr role="row">
                    <th
                      role="columnheader"
                      scope="col"
                      aria-colindex="2"
                      aria-sort="none"
                      class="position-relative"
                    >
                      <div>Image</div>
                      <span class="sr-only"> (Click to sort ascending)</span>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      aria-colindex="1"
                      aria-sort="ascending"
                      class="position-relative"
                    >
                      <div>Name</div>
                      <span class="sr-only"> (Click to sort descending)</span>
                    </th>

                    <th
                      role="columnheader"
                      scope="col"
                      aria-colindex="3"
                      aria-sort="none"
                      class="position-relative"
                    >
                      <div>State</div>
                      <span class="sr-only"> (Click to sort ascending)</span>
                    </th>

                    <th
                      role="columnheader"
                      scope="col"
                      aria-colindex="4"
                      aria-sort="none"
                      class="position-relative"
                    >
                      <div>Action</div>
                      <span class="sr-only"> (Click to sort ascending)</span>
                    </th>
                  </tr>
                </thead>
                <tbody role="rowgroup">
                  <tr
                    v-for="category in paginatedCategories"
                    :key="category.id"
                    role="row"
                    tabindex="0"
                    aria-selected="false"
                  >
                    <td aria-colindex="2" role="cell">
                      <a
                        :href="`/category/detail/${category.id}`"
                        class="d-flex fixed-size-image"
                      >
                        <img
                          :src="getCategoryImage(category)"
                          alt="Category Image"
                          class="list-thumbnail responsive border-0 fixed-size-image"
                        />
                      </a>
                    </td>
                    <td aria-colindex="1" role="cell" class="list-item-heading">
                      <a :href="`/category/detail/${category.id}`">{{
                        category.attributes.name
                      }}</a>
                    </td>

                    <td aria-colindex="3" role="cell">
                      <span
                        v-if="category.attributes.state === 'published'"
                        class="badge badge-success"
                        >Published</span
                      >
                      <span v-else class="badge badge-secondary"
                        >Not Published</span
                      >
                    </td>

                    <td aria-colindex="4" role="cell">
                      <div class="">
                        <button
                          type="button"
                          class="btn icon-button large ml-1 btn-outline-success"
                          @click="goToUpdateCategory(category.id)"
                        >
                          <i class="iconsminds-repair"></i>
                        </button>
                        <button
                          type="button"
                          class="btn icon-button large ml-1 btn-outline-danger"
                          @click="deleteCategory(category.id)"
                        >
                          <i class="iconsminds-close"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <ul
                role="menubar"
                aria-disabled="false"
                aria-label="Pagination"
                class="pagination b-pagination pagination-sm justify-content-center"
              >
                <li
                  role="presentation"
                  class="page-item"
                  :class="{ disabled: currentPage === 1 }"
                >
                  <button
                    role="menuitem"
                    aria-label="Go to first page"
                    @click="goToPage(1)"
                    :disabled="currentPage === 1"
                    class="page-link"
                  >
                    <i class="simple-icon-control-start"></i>
                  </button>
                </li>
                <li
                  role="presentation"
                  class="page-item"
                  :class="{ disabled: currentPage === 1 }"
                >
                  <button
                    role="menuitem"
                    aria-label="Go to previous page"
                    @click="prevPage"
                    :disabled="currentPage === 1"
                    class="page-link"
                  >
                    <i class="simple-icon-arrow-left"></i>
                  </button>
                </li>
                <li
                  v-for="page in totalPages"
                  :key="page"
                  role="presentation"
                  class="page-item"
                  :class="{ active: currentPage === page }"
                >
                  <button
                    role="menuitemradio"
                    type="button"
                    @click="goToPage(page)"
                    :aria-checked="currentPage === page"
                    :aria-posinset="page"
                    :aria-setsize="totalPages"
                    class="page-link"
                  >
                    {{ page }}
                  </button>
                </li>
                <li
                  role="presentation"
                  class="page-item"
                  :class="{ disabled: currentPage === totalPages }"
                >
                  <button
                    role="menuitem"
                    aria-label="Go to next page"
                    @click="nextPage"
                    :disabled="currentPage === totalPages"
                    class="page-link"
                  >
                    <i class="simple-icon-arrow-right"></i>
                  </button>
                </li>
                <li
                  role="presentation"
                  class="page-item"
                  :class="{ disabled: currentPage === totalPages }"
                >
                  <button
                    role="menuitem"
                    aria-label="Go to last page"
                    @click="goToPage(totalPages)"
                    :disabled="currentPage === totalPages"
                    class="page-link"
                  >
                    <i class="simple-icon-control-end"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import strapi from "@/services/strapi";

export default {
  name: "CategoryList",
  data() {
    return {
      categories: [],
      totalRoutes: 0,
      totalPages: 0,
      routesPerPage: 10, // Default value
      currentPage: 1,
      searchQuery: "",
    };
  },
  computed: {
    paginatedCategories() {
      const startIndex = (this.currentPage - 1) * this.routesPerPage;
      const endIndex = startIndex + this.routesPerPage;
      return this.categories.slice(startIndex, endIndex);
    },
  },
  async created() {
    await this.fetchCategories();
  },
  watch: {
    routesPerPage(newVal, oldVal) {
      console.log(`routesPerPage changed from ${oldVal} to ${newVal}`);
      this.currentPage = 1; // Reset to the first page when changing the number of items per page
      this.fetchCategories();
    },
    currentPage(newVal, oldVal) {
      console.log(`currentPage changed from ${oldVal} to ${newVal}`);
      this.fetchCategories();
    },
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) return "N/A";
      const date = new Date(dateString);
      if (isNaN(date)) return "Invalid Date";
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
      return date.toLocaleDateString(undefined, options);
    },
    async togglePublishState(category) {
      if (category.attributes.state === "published") {
        const confirmUnpublish = confirm(
          "If you don't publish this content, it will automatically turn into a Draft. Are you sure you don't want to publish it?"
        );
        if (!confirmUnpublish) {
          return;
        }
      }
      const newState =
        category.attributes.state === "published" ? "draft" : "published";
      try {
        await this.updateCategoryState(category.id, newState);
        category.attributes.state = newState; // Update the local state for immediate feedback
      } catch (error) {
        console.error("Failed to update category state", error);
      }
    },
    getCategoryImage(category) {
      const baseURL = "http://localhost:1337";
      const image = category.attributes.image.data;

      if (image) {
        const imgAttributes = image.attributes;
        if (
          imgAttributes.formats &&
          imgAttributes.formats.thumbnail &&
          imgAttributes.formats.thumbnail.url
        ) {
          return `${baseURL}${imgAttributes.formats.thumbnail.url}`;
        } else if (imgAttributes.url) {
          return `${baseURL}${imgAttributes.url}`;
        }
      }
      return "/default-category-image.jpg";
    },
    async fetchCategories() {
      console.log("Fetching categories...");
      try {
        const response = await strapi.get(
          `/categories?populate=*&filters[name][$contains]=${this.searchQuery}`
        );
        this.categories = response.data.data.map((category) => ({
          ...category,
          showFullDescription: false,
        }));

        this.totalRoutes = this.categories.length;
        this.totalPages = Math.ceil(this.totalRoutes / this.routesPerPage);
        console.log("Categories fetched:", this.categories);
        console.log("Total routes:", this.totalRoutes);
        console.log("Total pages:", this.totalPages);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },
    goToUpdateCategory(categoryId) {
      // Navigate to UpdateCategory with the categoryId
      this.$router.push({ name: "UpdateCategory", params: { id: categoryId } });
    },
    async deleteCategory(categoryId) {
      try {
        const response = await strapi.delete(`/categories/${categoryId}`);

        if (response.status === 200) {
          // Remove deleted category from the local array
          this.categories = this.categories.filter(
            (category) => category.id !== categoryId
          );

          alert("Catégorie supprimée avec succès !");
        } else {
          throw new Error("Échec de la suppression de la catégorie.");
        }
      } catch (error) {
        console.error("Échec de la suppression de la catégorie.", error);
        alert("Échec de la suppression de la catégorie. Veuillez réessayer.");
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToPage(pageNumber) {
      this.currentPage = pageNumber;
    },
    setRoutesPerPage(number) {
      console.log("Setting routes per page to:", number);
      this.routesPerPage = number;
    },
    toggleDescription(category) {
      category.showFullDescription = !category.showFullDescription;
    },
    truncateText(text, length) {
      if (text.length <= length) {
        return text;
      }
      return text.split(" ").slice(0, length).join(" ") + "...";
    },
    goToAddProduct() {
      // Use router.push to navigate programmatically
      this.$router.push({ name: "CreateCategory" });
    },
  },
};
</script>
<style scoped>
.category-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.vue-line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.listing-heading {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}
.fixed-buttons {
  position: absolute;
  right: 20px; /* Adjust as needed */
  bottom: 7px; /* Adjust as needed */
}
.listing-desc {
  font-size: 1rem;
}

.fixed-size-image {
  width: 100px; /* Set your desired width */
  height: 100px; /* Set your desired height */
  overflow: hidden;
}
.d-flex {
  margin-right: 20px; /* Adjust margin-right as needed */
}
</style>
