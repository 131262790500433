<template>
  <div id="app">
    <NavbarSlider v-if="!isLoginPage" @toggle-sidebar="toggleSidebar" />
    <div :class="sidebarClass" v-if="isSidebarOpen && !isLoginPage">
      <div ref="sidebar">
        <div class="main-menu">
          <section class="ps-container scrollbar">
            <ul class="list-unstyled">
              <!-- Sidebar links -->
              <li>
                <a @click="toggleCategoryDropdown">
                  <i class="simple-icon-menu"></i> Category
                </a>

                <ul
                  :class="{ dropdown: true, open: isCategoryDropdownOpen }"
                  aria-current="page"
                >
                  <li class="li1">
                    <router-link to="/Category" aria-current="page">
                      Category
                    </router-link>
                  </li>
                  <li class="li1">
                    <router-link to="/SousCategory" aria-current="page">
                      Sub-category
                    </router-link>
                  </li>
                  <li class="li1">
                    <router-link to="/ChildCategory" aria-current="page">
                      Child Category
                    </router-link>
                  </li>
                  <li class="li1">
                    <router-link to="/Attributes" aria-current="page">
                      Attributes
                    </router-link>
                  </li>
                  <li class="li1">
                    <router-link to="/Options" aria-current="page">
                      Options
                    </router-link>
                  </li>
                </ul>
              </li>
              <li :class="{ active: $route.path === '/Product' }">
                <router-link to="/Product" aria-current="page">
                  <i class="iconsminds-server-2"></i> Product
                </router-link>
              </li>
              <li :class="{ active: $route.path === '/Brands' }">
                <router-link to="/Brands" aria-current="page">
                  <i class="iconsminds-building"></i> Brand
                </router-link>
              </li>
              <li :class="{ active: $route.path === '/Dashboards' }">
                <router-link to="/Dashboards" aria-current="page">
                  <i class="iconsminds-shop-4"></i> Orders
                </router-link>
              </li>
              <li :class="{ active: $route.path === '/Pages' }">
                <router-link to="/Pages" aria-current="page">
                  <i class="iconsminds-digital-drawing"></i> E-commerce
                </router-link>
              </li>
              <li :class="{ active: $route.path === '/Client' }">
                <router-link to="/Client" aria-current="page">
                  <i class="iconsminds-user"></i> Clients
                </router-link>
              </li>
              <li :class="{ active: $route.path === '/Applications' }">
                <router-link to="/Applications" aria-current="page">
                  <i class="iconsminds-air-balloon-1"></i> FAQ
                </router-link>
              </li>
              <li :class="{ active: $route.path === '/UI' }">
                <router-link to="/UI" aria-current="page">
                  <i class="iconsminds-pantone"></i> Subscribers
                </router-link>
              </li>
              <li :class="{ active: $route.path === '/User' }">
                <router-link to="/User" aria-current="page">
                  <i class="iconsminds-men"></i> Users
                </router-link>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </div>
    <div class="main-content">
      <router-view></router-view>
    </div>
    <FooterBar v-if="!isLoginPage" />
  </div>
</template>

<script>
import NavbarSlider from "./components/Layout/NavbarSlider.vue";
import FooterBar from "./components/Layout/FooterBar.vue";

export default {
  name: "App",

  components: {
    NavbarSlider,
    FooterBar,
  },
  created() {
    this.$router.push("/login");
  },
  data() {
    return {
      isSidebarOpen: true,
      isCategoryDropdownOpen: false,
    };
  },
  computed: {
    sidebarClass() {
      return {
        sidebar: true,
        // Add other classes as needed based on your design
      };
    },
    isLoginPage() {
      return this.$route.path === "/login";
    },
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    toggleCategoryDropdown() {
      this.isCategoryDropdownOpen = !this.isCategoryDropdownOpen;
    },
  },
};
</script>

<style scoped>
nav ul {
  list-style-type: none;
  padding: 0;
}

nav li {
  display: inline;
  margin-right: 1rem;
}

.main-content {
  margin-left: 250px; /* Adjust based on sidebar width */
  transition: margin-left 0.3s ease-in-out;
  margin-top: 145px;
  margin-right: 60px;
  margin-bottom: 40px;
}

.scrollbar {
  height: 100%;
  overflow-y: auto;
  padding: 0 10px;
}

.scrollbar::-webkit-scrollbar {
  width: 5px;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #a3a3a3;
}

.dropdown {
  list-style-type: none;
  padding: 0;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  position: absolute;
  z-index: 1000;
  top: 100%;
  left: 0;
  min-width: 134px;
  display: none; /* Initially hidden */
  transition: all 0.3s ease;
}

.dropdown.open {
  display: contents;
}

.dropdown li {
  padding: 10px 20px;
  transition: background-color 0.3s;
}

.dropdown li:hover {
  background-color: #f0f0f0;
}

.dropdown li.router-link-active {
  font-weight: bold;
}

.dropdown li.router-link-active a {
  color: #333;
}
.li1 {
  height: 50px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  border-bottom: 1px solid #f3f3f3;
  color: #3a3a3a;
  transition: color 0.3s;
  transition: background 0.3s;
}
</style>
